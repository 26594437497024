window.onload = function() {
  new Vue({
    el: '#app',
    _scopeId: 'data-v-aeebbc60',
    owCSS: `.owhn-app[data-v-aeebbc60]{--ow-primary-color:#424a54;--ow-highlight-color:#cddc39;--ow-back-color:#f5f5f5;--ow-front-color:#fff;--ow-label-color:#4d4d4d;--ow-text-color:#000;color:var(--ow-text-color);display:block;background:#f5f5f5;width:100%;overflow:auto;min-height:100vh;max-width:1000px;margin:0 auto;padding:0}.slide-left-enter-active[data-v-aeebbc60],.slide-right-leave-active[data-v-aeebbc60]{box-shadow:0 0 10px 4px hsla(0,0%,62%,.24);z-index:2000;position:fixed;top:0;background:#f0f2f5;right:0;bottom:0;left:0;will-change:transform;min-height:100vh;position:fixed!important}.slide-left-enter-active[data-v-aeebbc60],.slide-right-leave-active[data-v-aeebbc60]{transition:transform .25s ease-out}.slide-left-enter[data-v-aeebbc60],.slide-right-leave-to[data-v-aeebbc60],.slide-right-leave[data-v-aeebbc60]{transform:translate3d(100%,0,0)}.slide-left-leave-active[data-v-aeebbc60],.slide-left-leave-to[data-v-aeebbc60],.slide-right-enter-active[data-v-aeebbc60],.slide-right-enter[data-v-aeebbc60]{display:none}.fade-enter-active[data-v-aeebbc60],.fade-leave-active[data-v-aeebbc60]{transition:opacity 1s ease}.fade-enter-from[data-v-aeebbc60],.fade-leave-to[data-v-aeebbc60]{opacity:0}@media (min-width:768px){.owhn-app[data-v-aeebbc60]{max-width:100%}}`,
    render: function render() {
      var _vm = this;
      var _h = _vm.$createElement;
      var _c = _vm._self._c || _h;
      return _c('div', {
        staticClass: "owhn-app",
        attrs: {
          "id": "app"
        }
      }, [(_vm.owRegistry['owvc-common-styles']) ? _c('owvc-common-styles') : _vm._e(), _vm._v(" "), (_vm.owRegistry['chatbot-icon'] && _vm.showChatBotIcon) ? _c('chatbot-icon') : _vm._e(), _vm._v(" "), _c('transition', {
        attrs: {
          "name": _vm.transitionName,
          "mode": 'in-out',
          "duration": 250
        },
        on: {
          "leave": _vm.leave,
          "afterLeave": _vm.afterLeave,
          "enter": _vm.enter,
          "afterEnter": _vm.afterEnter
        }
      }, [
        [_c('keep-alive', {
          attrs: {
            "include": _vm.cachedComponents
          }
        }, [_c('router-view', {
          key: _vm.$route.name,
          staticClass: "view"
        })], 1)]
      ], 2), _vm._v(" "), (_vm.owRegistry['cookie-consent']) ? _c('cookie-consent') : _vm._e(), _vm._v(" "), (_vm.owRegistry['ow-ajax']) ? _c('ow-ajax', {
        attrs: {
          "trigger": _vm.fetchUserDetails,
          "url": _vm.userDetailsUrl,
          "method": "post",
          "body": _vm.fetchUserDetailsBody
        },
        on: {
          "error": _vm.fetchUserErrorCB,
          "success": _vm.fetchUserSuccessCB
        }
      }) : _vm._e()], 1)
    },
    staticRenderFns: [],

    router: router,
    store: store,
    data: function data() {
      return {
        cachedComponents: ["VeDashboard"],
        fetchUserDetails: false,
        userDetailsUrl: "vc-api/consumerservice/rest/v1/user/attribute/fetch",
        fetchUserDetailsBody: {
          attribute_name: ["bookmark"],
          attribute_type: ["bookmark_count"],
          profile_needed: true
        },
        counter: 0
      };
    },

    computed: {
      showChatBotIcon: function showChatBotIcon() {
        return this.$store.state.showChatbotIcon && this.$store.state.isZenDeskScriptLoaded;
      }
    },
    watch: {
      "$store.state.selectedCityData": function $storeStateSelectedCityData(newVal) {
        if (newVal) {
          if (newVal.bottomNavbar || newVal.bottom_navbar) {
            if (this.isMobile) {
              this.loadOWComponent("bottom-nav-bar", "components/bottom-nav-bar.js");
            }
          }
        }
      },
      $route: function $route(newVal, oldVal) {
        if (oldVal.name === "ve-listings") {
          var lastData = Object.assign({}, this.$store.state.lastRecentlyViewedPageData);
          if (lastData && lastData.action_performed !== "back_button_click" && lastData.action_performed !== "car_clicked" || !lastData && !Object.keys(lastData).length) {
            this.recentlyViewedPageData({
              page: this.$route.name,
              item_clicked: "device_navigation",
              action_performed: "route_change",
              is_default_filter: this.$store.state.selectedFilters && Object.keys(this.$store.state.selectedFilters).length ? 0 : 1,
              is_default_sort: this.$store.state.selectedSortKey && this.$store.state.selectedSortKey === "REL" ? 1 : 0,
              filters_applied_data: this.$store.state.selectedFilters,
              sort_applied_data: this.$store.state.selectedSortKey
            });
          }
        }
      }
    },
    created: function created() {
      this.loadExternalScripts();
      this.setLocalStorageIsWebview();

      if (this.$route.query.action) {
        this.handleDeeplink();
      }

      if (window.__INITIAL_STATE__) {
        store.replaceState(window.__INITIAL_STATE__);
      }

      this.fetchUserDetails = true;

      window.addEventListener("beforeunload", this.removeUtmTags);
    },
    mounted: function mounted() {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "manual";
      }
      localStorage.setItem("moEngageAppId", window.owConfig.moEngageAppId);
      localStorage.setItem("moEngageDebugLogs", window.owConfig.moEngageDebugLogs);

      this.setupMutationObserver();
      this.triggerEventsOnAppLeft();
    },
    beforeDestroy: function beforeDestroy() {
      if (window.owConfig.isAllowBatchEvents) {
        document.removeEventListener("visibilitychange", this.triggerMobileBatchEvents);
        window.removeEventListener("beforeunload", this.triggerBatchEventOnPageLeft);
      }
    },

    methods: {
      imageLoadHander: function imageLoadHander(event) {
        var now = window.performance ? window.performance.now() : new Date().getTime();
        var src = window.encodeURI(event.target.getAttribute("src"));

        var imageLoadStartTime = window.imageCount[src] ? window.imageCount[src].loadStartTime : now;
        if (window.loadedImageCount[src] && window.loadedImageCount[src].calculated) {
          return;
        }
        window.loadedImageCount[src] = {
          loadStartTime: window.imageCount[src] ? window.imageCount[src].loadStartTime : now,
          loadEndTime: now,
          loadTime: now - imageLoadStartTime,
          calculated: true,
          fromCache: window.imageCount[src] ? window.imageCount[src].fromCache : false
        };
        if (Object.keys(window.imageCount).length == Object.keys(window.loadedImageCount).length) {
          var loadTimeArray = [];
          Object.entries(window.loadedImageCount).forEach(function(item) {
            item[1].url = item[0];
            loadTimeArray.push(item[1]);
          });
          var sortedTime = loadTimeArray.sort(function(a, b) {
            return Number(b.loadTime) - Number(a.loadTime);
          });

          this.$store.dispatch("IMAGES_SORTED_TIME", sortedTime);
          this.$store.dispatch("SET_ALL_IMAGES_LOADED", true);
        }
      },
      checkURL: function checkURL(url) {
        return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
      },
      mutationCallback: function mutationCallback(mutationsList) {
        var _this = this;

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = mutationsList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var mutation = _step.value;

            mutation.addedNodes.forEach(function(addedNode) {
              if (!addedNode.querySelectorAll) {
                return;
              }
              var imgNodes = addedNode.querySelectorAll("img");
              if (imgNodes.length > 0) {
                _this.$store.dispatch("SET_ALL_IMAGES_LOADED", false);
                imgNodes.forEach(function(eachImage) {
                  var now = window.performance.now();
                  if (!_this.checkURL(eachImage.src)) {
                    return;
                  }
                  window.imageCount[eachImage.src] = {
                    loadStartTime: now,
                    fromCache: eachImage.complete
                  };

                  eachImage.addEventListener("load", _this.imageLoadHander);
                  eachImage.addEventListener("error", _this.imageLoadHander);
                });
              }
            });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      },
      setupMutationObserver: function setupMutationObserver() {
        var mutationTargetNode = document.getElementById("documentBody");
        var mutationConfig = {
          attributes: false,
          childList: true,
          subtree: true
        };
        var observer = new MutationObserver(this.mutationCallback);
        observer.observe(mutationTargetNode, mutationConfig);
      },
      setLocalStorageIsWebview: function setLocalStorageIsWebview() {
        if (window.serverData.isWebview && window.localStorage) {
          localStorage.setItem("isWebview", true);
        }
      },
      loadExternalScripts: function loadExternalScripts() {
        if (this.isWebview) {
          this.loadServiceWorker();
        }
      },
      loadServiceWorker: function loadServiceWorker() {
        if (!window.owConfig.devMode) {
          var script = document.createElement("script");
          script.type = "module";
          script.src = "sw-oc-reg.js";
          document.head.appendChild(script);
        }
      },
      leave: function leave() {
        if (this.transitionName && this.transitionName === this.DEFAULT_TRANSITION) {
          this.$store.dispatch("SET_TRANSITION", true);
        }
      },
      afterLeave: function afterLeave() {
        if (this.transitionName && this.transitionName === this.DEFAULT_TRANSITION) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;

          this.$store.dispatch("SET_TRANSITION", false);
        }
      },
      enter: function enter() {
        if (this.transitionName && this.transitionName !== this.DEFAULT_TRANSITION) {
          setTimeout(function() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }, 200);
          this.$store.dispatch("SET_TRANSITION", true);
        }
      },
      afterEnter: function afterEnter() {
        if (this.transitionName && this.transitionName !== this.DEFAULT_TRANSITION) {
          this.$store.dispatch("SET_TRANSITION", false);
        }
      },
      fetchUserErrorCB: function fetchUserErrorCB() {
        this.fetchUserDetails = false;
        localStorage.setItem("userLoggedIn", "no");
        this.$store.dispatch("SET_USER_LOGGED_IN_STATUS", false);
        this.$store.dispatch("SET_USER_DETAILS", {});
      },
      fetchUserSuccessCB: function fetchUserSuccessCB(_ref) {
        var response = _ref.response;

        if (response.status == "SUCCESS") {
          localStorage.setItem("userLoggedIn", "yes");
          this.$store.dispatch("SET_USER_LOGGED_IN_STATUS", true);
          this.$store.dispatch("SET_USER_DETAILS", response.data);
        } else {
          this.fetchUserErrorCB();
        }
        this.fetchUserDetails = false;
      },
      removeUtmTags: function removeUtmTags() {
        var itemsToRemove = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
        itemsToRemove.map(function(item) {
          localStorage.removeItem(item);
        });
      },
      triggerEventsOnAppLeft: function triggerEventsOnAppLeft() {
        if (window.owConfig.isAllowBatchEvents) {
          if (this.isMobile) {
            document.addEventListener("visibilitychange", this.triggerMobileBatchEvents);
          } else {
            window.addEventListener("beforeunload", this.triggerBatchEventOnPageLeft);
          }
        }
      },
      triggerMobileBatchEvents: function triggerMobileBatchEvents() {
        if (document.visibilityState === "hidden") {
          this.triggerBatchEventOnPageLeft();
        }
      }
    }

  });
}